import axios from 'axios';

export default {
	async createSlackWebhook(cg_id, data){
		try {
			const response = await axios.post(`/api/slack/webhook/${cg_id}/`, data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	},
	async listSlackWebhooks(cg_id, fetch_all){
		try {
			let url = `/api/slack/webhook/${cg_id}/`
			if (fetch_all) {
				url += '?all=true'
			}
			const response = await axios.get(url);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	},
	async deleteWebhook(cg_id, webhook_id){
		try {
			const response = await axios.delete(`/api/slack/customer_group/${cg_id}/webhook/${webhook_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async editSlackWebhook(cg_id, webhook_id, data){
		try {
			const response = await axios.put(`/api/slack/customer_group/${cg_id}/webhook/${webhook_id}/`, data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	},
	async sendQuickMessage(data){
		try {
			const response = await axios.post('/api/slack/webhook/send_quick_message/', data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	},
	async getSlackReports(){
		try {
			const response = await axios.get('/api/slack/reports/');
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	}
}
