<template>
	<q-card style="width: 700px; max-width: 80vw;">
		<q-card-section>
			<q-select
				v-model="selected_webhooks"
				:error="has_selected_webhooks_error"
				:error-message="selected_webhooks_error_message"
				:label="'Select Webhook'"
				:option-label="(item) => item.webhook_name"
				:options="webhooks"
				class="q-mb-lg"
				multiple
			/>
			<q-input
				v-model="message_text"
				:error="has_message_text_error"
				:error-message="message_text_error_message"
				class="q-mb-lg"
				label="Message Text"
				outlined
				type="textarea"
			/>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('close')"/>
			<q-btn color="primary" flat label="Send" @click="sendMessage()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapGetters } from 'vuex';
import SlackApi from '@/services/api/Slack.js';

export default {
	name: 'SendMessageDialog',
	data() {
		return {
			message_text: '',
			has_message_text_error: false,
			message_text_error_message: '',
			webhooks: [],
			selected_webhooks: [],
			has_selected_webhooks_error: false,
			selected_webhooks_error_message: '',
		}
	},
	computed: {
		...mapGetters(['current_CG']),
	},
	methods: {
		resetErrors() {
			this.has_selected_webhooks_error = false
			this.has_message_text_error = false
			this.selected_webhooks_error_message = ''
			this.message_text_error_message = ''
		},
		checkErrors() {
			let has_error = false
			if (!this.selected_webhooks.length) {
				has_error = true
				this.has_selected_webhooks_error = true
				this.selected_webhooks_error_message = 'Please select a webhook'
			}
			if (!this.message_text) {
				has_error = true
				this.has_message_text_error = true
				this.message_text_error_message = "Please write a message"
			}
			return has_error
		},
		async sendMessage() {
			let data = {}
			this.resetErrors()
			let has_errors = this.checkErrors()
			if (has_errors) {
				return
			}
			data['message'] = this.message_text
			data['webhooks_ids'] = Object.keys(this.selected_webhooks).map(k => this.selected_webhooks[k].id)
			this.$q.loading.show({
				delay: 400,
			})
			let response = await SlackApi.sendQuickMessage(data)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: Object.values(response.error_message),
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$q.loading.hide()
				return
			}
			this.$q.notify({
				timeout: 6700,
				message: response
			})

			this.$q.loading.hide()
			this.$emit('close')
			this.$router.push({name: "Slack Webhooks Reports"})
		}
	},
	async created() {
		this.webhooks = await SlackApi.listSlackWebhooks(this.current_CG.customer_group.id, true)
	}
}
</script>
